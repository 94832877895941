/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import tippy from 'tippy.js'
import form from '@/store/form/index'
import formRegistration from '@/store/form/registration/index'
import formLogin from '@/store/form/login/index'
import formPersonal from '@/store/form/personal/index'
import fileXls from '@/store/file/xls/index'
import topic from '@/store/topic'
import './assets/js/css/ImportCss'
import Cookies from 'js-cookie'
import { Fancybox } from '@fancyapps/ui'
import Faq from '@/assets/js/Faq/Faq'
import FaqCompany from '@/assets/js/Faq/FaqCompany'
import ReviewButton from '@/assets/js/Review/ReviewButton'
import Gallery from '@/assets/js/Gallery/Gallery'
import Comments from '@/assets/js/Comments/Comments'
import States from '@/assets/js/States/States'
import FilterSection from '@/assets/js/FilterSection/FilterSection'
import FilterToggle from '@/assets/js/Filter/Toggle/FilterToggle'
import HeaderMobile from '@/assets/js/HeaderMobile/HeaderMobile'
import AjaxFavorite from '@/assets/js/Ajax/AjaxFavorite/AjaxFavorite'
import AjaxLike from '@/assets/js/Ajax/AjaxLike/AjaxLike'
import LinkReferrer from '@/assets/js/LinkReferrer/LinkReferrer'
import MenuForum from '@/assets/js/Menu/MenuForum'
import TopicRead from '@/assets/js/Topic/TopicRead/TopicRead'
import SearchButton from '@/assets/js/Search/SearchButton'
import VideoImg from '@/assets/js/Video/VideoImg/VideoImg'
import ButtonMoreFilter from '@/assets/js/Button/More/Filter/ButtonMoreFilter'
import ButtonApply from '@/assets/js/Button/Apply/ButtonApply'
import Political from '@/assets/js/Political/Political'
import TabNumber from '@/assets/js/Tab/Number/Number'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import ButtonCommentAll from '@/assets/js/Button/Comment/All/ButtonCommentAll'
import AppFormSubscribe from '@/components/Form/FormSubscribe/AppFormSubscribe'
import AppFormSubscribeIndex from '@/components/Form/FormSubscribeIndex/AppFormSubscribeIndex'
import AppFormSubscribePersonal from '@/components/Form/FormSubscribePersonal/AppFormSubscribePersonal'
import AppFormMain from '@/components/Form/FormMain/AppFormMain'
import AppFormRegistration from '@/components/Form/FormRegistration/AppFormRegistration'
import AppFormLogin from '@/components/Form/FormLogin/AppFormLogin'
import AppFormFeedback from '@/components/Form/FormFeedback/AppFormFeedback'
import AppFormApply from '@/components/Form/FormApply/AppFormApply'
import AppFormPersonal from '@/components/Form/FormPersonal/AppFormPersonal'
import AppFileXls from '@/components/Email/XLS/AppFileXls'
import AppFilterStates from '@/components/Filter/FilterStates/AppFilterStates'
import AppTopicCreate from '@/components/Topic/TopicCreate/AppTopicCreate'
import AppTopicComment from '@/components/Topic/TopicComment/AppTopicComment'
import AppSearch from '@/components/Search/AppSearch'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')
window.Cookies = Cookies

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-subscribe]')
    new Faq()
    new ReviewButton()
    new Gallery()
    new Comments()
    new States()
    new FilterSection()
    new FilterToggle()
    new FaqCompany()
    new HeaderMobile()
    new AjaxFavorite()
    new AjaxLike()
    new LinkReferrer()
    new MenuForum()
    new TopicRead()
    new SearchButton()
    new VideoImg()
    new ButtonMoreFilter()
    new ButtonApply()
    new Political()
    new TabNumber()
    new SwiperSlider()
    new ButtonCommentAll()

    tippy('.tippy--icon', {
        theme: 'material',
        arrow: true,
        placement: 'bottom-start',
        animation: 'scale-extreme',
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true
    })

    tippy('.tippy--review', {
        theme: 'light',
        arrow: true,
        placement: 'bottom-start',
        animation: 'scale-extreme',
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true
    })

    if(document.getElementById('form-subscribe')!= null) {
        createApp(AppFormSubscribe).use(form).use(Maska).mount('#form-subscribe')
    }

    if(document.getElementById('form-subscribe-index')!= null) {
        createApp(AppFormSubscribeIndex).use(form).use(Maska).mount('#form-subscribe-index')
    }

    if(document.getElementById('form-subscribe-personal')!= null) {
        createApp(AppFormSubscribePersonal).use(form).use(Maska).mount('#form-subscribe-personal')
    }

    if(document.getElementById('form-main')!= null) {
        createApp(AppFormMain).use(form).use(Maska).mount('#form-main')
    }

    if(document.getElementById('form-registration')!= null) {
        createApp(AppFormRegistration).use(formRegistration).use(Maska).mount('#form-registration')
    }

    if(document.getElementById('form-login')!= null) {
        createApp(AppFormLogin).use(formLogin).use(Maska).mount('#form-login')
    }

    if(document.getElementById('form-feedback')!= null) {
        createApp(AppFormFeedback).use(form).use(Maska).mount('#form-feedback')
    }

    if(document.getElementById('form-apply')!= null) {
        createApp(AppFormApply).use(form).use(Maska).mount('#form-apply')
    }

    if(document.getElementById('form-personal')!= null) {
        createApp(AppFormPersonal).use(formPersonal).use(Maska).mount('#form-personal')
    }

    if(document.getElementById('block-file-xls')!= null) {
        createApp(AppFileXls).use(fileXls).use(Maska).mount('#block-file-xls')
    }

    if(document.getElementById('search-states')!= null) {
        createApp(AppFilterStates).mount('#search-states')
    }

    if(document.getElementById('block-topic-create')!= null) {
        createApp({})
            .component('topic-create', AppTopicCreate)
            .use(topic)
            .mount('#block-topic-create')
    }

    const commentsObj = document.querySelectorAll(".topic__comment--form");
    commentsObj.forEach(commentObj => {
        createApp({
            el: commentObj,
            components:{
                'topic-comment': AppTopicComment
            }
        }).mount(commentObj)
    })

    if(document.getElementById('block--search')!= null) {
        createApp({})
            .component('block-search', AppSearch)
            .mount('#block--search')
    }
})
