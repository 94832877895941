/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonCommentAll {
    classItem = 'topic'
    classHeader = 'page-header'
    classComments = 'topic__comments'
    classButton = 'button-comment-all--button';
    classButtonAll = 'button-more-comments'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let heightHeader = $(`.${self.classHeader}`).height()

            $(this).closest(`.${self.classItem}`).find(`.${self.classButtonAll}`).click()

            $('html,body').stop().animate(
                {
                    scrollTop: $(this).closest(`.${self.classItem}`).find(`.${self.classComments}`).offset().top - heightHeader - 20
                },
                500
            )
        })
    }
}
